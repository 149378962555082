import { helperMixin, infinitySrollJobApplicantByStatus } from "@/mixins";
import { defineComponent } from "vue";
import { JobApplicantStatus } from "@/enums";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import jobApplicantHttp from "@/http/jobApplicant";
import ProfileDetailModal from "@/components/JobApplicant/ProfileDetailModal.vue";
import jobHttp from "@/http/job";
import ConfirmModal from "@/components/ConfirmModal.vue";
export default defineComponent({
    components: {
        TeleportToContentFooter,
        BaseButtonPrimary,
        BaseCheckbox,
        UserCertificatesDownload,
        AvatarImg,
        BaseTable,
        BaseTableColumn,
        BaseTableRow,
        BaseButtonDanger,
        ProfileDetailModal,
        ConfirmModal,
    },
    mixins: [
        helperMixin,
        infinitySrollJobApplicantByStatus([JobApplicantStatus.Sent]),
    ],
    data() {
        return {
            showProfileDetailModal: false,
            selectedCandidates: [],
            job: {},
        };
    },
    computed: {
        jobApplicantHttp,
        jobHttp,
    },
    async mounted() {
        var _a, _b, _c, _d, _e, _f, _g;
        this.filter.job_id = (_b = (_a = this.currentRoute) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id;
        this.job =
            (_e = (await this.jobHttp.read((_d = (_c = this.currentRoute) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.id)).response.data) !== null && _e !== void 0 ? _e : {};
        if (this.job.job_id)
            (_g = (_f = this.appStore.state.activeMenu) === null || _f === void 0 ? void 0 : _f.breadcrumbs) === null || _g === void 0 ? void 0 : _g.unshift(this.job.job_id.toString());
    },
    methods: {
        showProfileDetail(jobApplicant) {
            this.selectedRow = jobApplicant;
            this.showProfileDetailModal = true;
        },
        resetAction() {
            this.selectedCandidates = [];
            this.reload();
        },
        async rejects() {
            this.loading = true;
            if (await this.requestHandler(this.jobApplicantHttp.rejects(this.selectedCandidates.map((candidate) => candidate.id)))) {
                this.loading = false;
                this.resetAction();
            }
        },
        async addToProcess() {
            this.loading = true;
            if (await this.requestHandler(this.jobApplicantHttp.process(this.selectedCandidates.map((candidate) => candidate.id)))) {
                this.loading = false;
                this.resetAction();
            }
        },
    },
});
